export const gateway = "https://mitch-bms-api.link.wawanesalife.com";
export const LoginUrlParameter = "https://stable-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=2ohn8sa8grl3tvumqvfkae841o&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fmitch-bms.link.wawanesalife.com%2Fredirect";
export const BrokerPoolClientParameter = "2ohn8sa8grl3tvumqvfkae841o";
export const BrokerPoolDomainParameter = "stable-brokers";
export const BrokerPoolParameter = "ca-central-1_Q5DSclAvd";

export const brokerBranding = {
	"mitch.link.wawanesalife.com": {
		a: {
			broker: "mitch",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://mitchinsurance.com/privacy-policy/",
			scope: "mitch#branding#mitch.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "info@mitchinsurance.com",
			video_link: "null",
			map2: "110 Yonge St. Suite 403,Toronto,ON,M5C 1T4",
			environment_id: "stable",
			map1: "1555 Wentworth St. Unit 200, Whitby,Whitby,ON,L1N 9T6",
			brands: "mitch.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "null",
			phone: "1-800-731-2228",
			menuPhone: "button",
			environment: "stable",
			website: "https://mitchinsurance.com",
			tcLink: "https://mitchinsurance.com/terms-conditions/",
			ftcLink: "null",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Say hi to lovable insurance!",
			approve_user: "link_mitch",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "mitch.link.wawanesalife.com",
		},
		b: {
			broker: "mitch",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://mitchinsurance.com/privacy-policy/",
			scope: "mitch#branding#mitch.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "info@mitchinsurance.com",
			video_link: "null",
			map2: "110 Yonge St. Suite 403,Toronto,ON,M5C 1T4",
			environment_id: "stable",
			map1: "1555 Wentworth St. Unit 200, Whitby,Whitby,ON,L1N 9T6",
			brands: "mitch.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "null",
			phone: "1-800-731-2228",
			menuPhone: "button",
			environment: "stable",
			website: "https://mitchinsurance.com",
			tcLink: "https://mitchinsurance.com/terms-conditions/",
			ftcLink: "null",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Say hi to lovable insurance!",
			approve_user: "link_mitch",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "mitch.link.wawanesalife.com",
		},
	},
};

//updated on Sun May 19 2024 10:36:43 GMT-0700 (Pacific Daylight Time)
